import React, { useState, useEffect } from 'react';
import Calendar from '../../assets/Images/Calendar2.svg';
import { IoIosArrowForward } from 'react-icons/io';
import './index.scss';
import usePageBackgroundColor from '../../components/usePageBackgroundColor';
import { getCookie } from '../../utils/cookieHelper';
import { useNavigate } from 'react-router-dom';

const TimeSheetHistory = () => {
  const [selectedPeriod, setSelectedPeriod] = useState('week');
  const [attendanceData, setAttendanceData] = useState([]);
  const [attendanceSummary, setAttendanceSummary] = useState({ week: 0, month: 0, year: 0 });
  const [currentPage, setCurrentPage] = useState(0); // New state for current page
  const rowsPerPage = 10; // Set number of rows per page
  const navigate = useNavigate();

  const periods = [
    { label: 'This Week', value: 'week' },
    { label: 'This Month', value: 'month' },
    { label: 'This Year', value: 'year' },
    { label: 'All Time', value: 'all' },
  ];

  usePageBackgroundColor('#EAF7FD');

  // Fetch attendance summary data
  const fetchAttendanceSummary = async () => {
    const employeeId = getCookie('dewcare_user')?.userId;
    try {
      const weekResponse = await fetch(`https://dewcare.co.uk/mobile/employee/attendance/week/${employeeId}`);
      const monthResponse = await fetch(`https://dewcare.co.uk/mobile/employee/attendance/month/${employeeId}`);
      const yearResponse = await fetch(`https://dewcare.co.uk/mobile/employee/attendance/year/${employeeId}`);

      const weekData = await weekResponse.json();
      const monthData = await monthResponse.json();
      const yearData = await yearResponse.json();

      setAttendanceSummary({
        week: weekData.attendance_records?.length || 0,
        month: monthData.attendance_records?.length || 0,
        year: yearData.attendance_records?.length || 0,
      });
    } catch (error) {
      console.error('Error fetching attendance summary:', error);
    }
  };

  // Fetch attendance data based on the selected period
  const fetchAttendanceData = async (period) => {
    let apiUrl;
    const employeeId = getCookie('dewcare_user')?.userId;

    switch (period) {
      case 'week':
        apiUrl = `https://dewcare.co.uk/mobile/employee/attendance/week/${employeeId}`;
        break;
      case 'month':
        apiUrl = `https://dewcare.co.uk/mobile/employee/attendance/month/${employeeId}`;
        break;
      case 'year':
        apiUrl = `https://dewcare.co.uk/mobile/employee/attendance/year/${employeeId}`;
        break;
      case 'all':
        apiUrl = `https://dewcare.co.uk/mobile/employee/attendance/all/${employeeId}`;
        break;
      default:
        return;
    }

    try {
      const response = await fetch(apiUrl);
      if (!response.ok) throw new Error('Failed to fetch attendance data');

      const data = await response.json();

      if (data && data.attendance_records) {
        setAttendanceData(data.attendance_records);
      } else {
        setAttendanceData([]);
      }
    } catch (error) {
      console.error('Error fetching attendance data:', error);
      setAttendanceData([]);
    }
  };

  // Handle period change
  const onPeriodChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedPeriod(selectedValue);
    setCurrentPage(0); // Reset to the first page on period change
    fetchAttendanceData(selectedValue);
  };

  // Handle click on a specific timesheet record to navigate to SubmittedSheet
  const handleRecordClick = (record) => {
    navigate('/submitted-sheet/:attendanceId', { state: { id: record.id } });
  };

  // Fetch data on mount for the default period and summary
  useEffect(() => {
    fetchAttendanceData(selectedPeriod);
    fetchAttendanceSummary();
  }, [selectedPeriod]);

  // Pagination Logic
  const totalPages = Math.ceil(attendanceData.length / rowsPerPage);
  const paginatedData = attendanceData.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Render table for "year" and "all time"
  const renderTable = () => (
    <div>
      <table className="attendance-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Total Hours</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.length > 0 ? (
            paginatedData.map((record, index) => (
              <tr key={index} onClick={() => handleRecordClick(record)}>
                <td>{record.date}</td>
                <td>{record.total_hours}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">No attendance data available.</td>
            </tr>
          )}
        </tbody>
      </table>
      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button onClick={prevPage} disabled={currentPage === 0}>
          Previous
        </button>
        <span>{` Page ${currentPage + 1} of ${totalPages} `}</span>
        <button onClick={nextPage} disabled={currentPage === totalPages - 1}>
          Next
        </button>
      </div>
    </div>
  );

  // Render list for "week" and "month"
  const renderList = () => (
    <div className="historyContainer">
      {paginatedData.length > 0 ? (
        paginatedData.map((record, index) => (
          <div key={index} className="history" onClick={() => handleRecordClick(record)}>
            <div className="group">
              <div className="iconContainer">
                <img src={Calendar} alt="Calendar" />
              </div>
              <div className="details">
                <p className="hour">Total Hours: {record.total_hours}</p>
                <p className="date">{record.date}</p>
              </div>
            </div>
            <div className="arrow">
              <IoIosArrowForward style={{ fontSize: 30 }} />
            </div>
          </div>
        ))
      ) : (
        <p>No attendance data available for this period.</p>
      )}
      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button onClick={prevPage} disabled={currentPage === 0}>
          Previous
        </button>
        <span>{` Page ${currentPage + 1} of ${totalPages} `}</span>
        <button onClick={nextPage} disabled={currentPage === totalPages - 1}>
          Next
        </button>
      </div>
    </div>
  );

  return (
    <div className="ts-history">
      <h1 className="header1">Time-Sheet History</h1>

      {/* Attendance Summary */}
      <div className="attendance">
        <div className="attendanceBox1">
          <p className="text1 small">Total days in attendance.</p>
          <p className="text1 large">{attendanceSummary.week}</p>
          <p className="whiteText">This Week</p>
        </div>
        <div className="attendanceBox2">
          <p className="text2 small">Total days in attendance.</p>
          <p className="text2 large">{attendanceSummary.month}</p>
          <p className="blueText">This Month</p>
        </div>
        <div className="attendanceBox2">
          <p className="text2 small">Total days in attendance.</p>
          <p className="text2 large">{attendanceSummary.year}</p>
          <p className="blueText">This Year</p>
        </div>
      </div>

      <h2 className="viewHistory">View Timesheet History</h2>

      {/* Period Dropdown */}
      <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
        <select value={selectedPeriod} onChange={onPeriodChange} style={{backgroundColor: '#fff', borderRadius: '5px', padding: '10px', border: 'none', fontWeight: '600'}}>
          {periods.map(period => (
            <option key={period.value} value={period.value}>{period.label}</option>
          ))}
        </select>
      </div>

      {/* Render Attendance Table/List */}
      {selectedPeriod === 'year' || selectedPeriod === 'all' ? renderTable() : renderList()}
    </div>
  );
};

export default TimeSheetHistory;
