import React from 'react';
import { Modal, Box, Typography, FormControlLabel, Radio, RadioGroup, Button } from '@mui/material';

const AvailabilityModal = ({ open, handleClose, selectedDayDetails, setSelectedDayDetails, date, handleSaveSelection }) => {
  const handleChange = (event) => {
    const selectedPeriod = event.target.value;
    setSelectedDayDetails({
      morning: selectedPeriod === 'morning',
      afternoon: selectedPeriod === 'afternoon',
      night: selectedPeriod === 'night',
      fullDay: selectedPeriod === 'fullDay',
    });
  };

  const handleSave = () => {
    handleSaveSelection();
    handleClose(); // Close the modal after saving
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          {date.toDateString()}
        </Typography>

        <RadioGroup value={Object.keys(selectedDayDetails).find(key => selectedDayDetails[key])} onChange={handleChange}>
          <FormControlLabel value="morning" control={<Radio />} label="Morning" />
          <FormControlLabel value="afternoon" control={<Radio />} label="Afternoon" />
          <FormControlLabel value="night" control={<Radio />} label="Night" />
          <FormControlLabel value="fullDay" control={<Radio />} label="Full Day" />
        </RadioGroup>

        <Button variant="contained" color="primary" onClick={handleSave} fullWidth>
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default AvailabilityModal;
