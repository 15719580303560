import React from 'react';
import './index.scss';
import { IoIosCopy } from "react-icons/io";
import bg from '../../assets/Images/Contact.png';
import PhoneSquared from '../../assets/Images/PhoneSquared.png'

const Contact = () => {

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert(`Copied to clipboard: ${text}`);
  };

  return (
    <div className="contact">
      <img src={bg} className="background" alt="Background" />
      <div className="con">
        <div>
          <h1 className="header">Need Support?</h1>
          <p className="text">In the event of an emergency requiring swift assistance, or for any general support needs, please contact us at:</p>
          <div className="contactInfo">
            <div className="support">
              <div className="group">
                <div className="iconCon">
                    <img src={PhoneSquared} alt=''/>
                </div>
                <div>
                  <p className="number">+44 (243) 53893589</p>
                  <p className="call">Call us</p>
                </div>
              </div>
                <IoIosCopy className="copyButton" onClick={() => copyToClipboard('+44 (243) 53893589')}/>
              
            </div>
            <div className="support">
              <div className="group">
                <div className="iconCon">
                <img src={PhoneSquared} alt=''/>
                </div>
                <div>
                  <p className="number">+1 (345) 37894838</p>
                  <p className="call">Call us</p>
                </div>
              </div>
              <IoIosCopy className="copyButton" onClick={() => copyToClipboard('+44 (243) 53893589')}/>
            </div>
            <div className="support">
              <div className="group">
                <div className="iconCon">
                <img src={PhoneSquared} alt=''/>
                </div>
                <div>
                  <p className="number">help@dewcare.co.uk</p>
                  <p className="call">Send us a mail</p>
                </div>
              </div>
              <IoIosCopy className="copyButton" onClick={() => copyToClipboard('+44 (243) 53893589')}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
