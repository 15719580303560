import React, { useState, useEffect, useRef } from 'react';
import { MdLocationOn } from "react-icons/md";
import SignatureCanvas from 'react-signature-canvas';
import './index.scss';
import { Container } from '@mui/material';
import usePageBackgroundColor from '../../components/usePageBackgroundColor';
import { getCookie } from '../../utils/cookieHelper';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const TimeSheet = () => {
    const [formData, setFormData] = useState({
        clockIn: "00:00",
        clockOut: "00:00",
        breakStart: "00:00",
        breakEnd: "00:00",
        punctuality: null,
        attitude: null,
        performance: null,
    });

    const [position, setPosition] = useState('');
    const [address, setAddress] = useState('');
    const sigCanvas = useRef({});
    const userId = getCookie('dewcare_user')?.userId;

    const todayDate = new Date().toLocaleDateString();

    usePageBackgroundColor('#E1E2E4');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchEmployeeDetails = async () => {
            try {
                if (userId) {
                    const response = await fetch(`https://dewcare.co.uk/mobile/employee/${userId}`);
                    const data = await response.json();
                    if (data.success) {
                        setPosition(data.data.position);
                        setAddress(data.data.address); // Set address
                    }
                }
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        };

        fetchEmployeeDetails();
    }, [userId]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const convertToTimestamp = (time) => {
        const [hours, minutes] = time.split(':');
        return `${parseInt(hours, 10).toString().padStart(2, '0')}${parseInt(minutes, 10).toString().padStart(2, '0')}00`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if all fields are filled
        if (
            !formData.clockIn ||
            !formData.clockOut ||
            !formData.breakStart ||
            !formData.breakEnd ||
            formData.punctuality === null ||
            formData.attitude === null ||
            formData.performance === null
        ) {
            toast.error("Please fill all fields"); // Show error toast
            return;
        }

        const signatureImage = sigCanvas.current.toDataURL("image/png");

        const signatureFormData = new FormData();
        signatureFormData.append("file", signatureImage);
        signatureFormData.append("upload_preset", "default_preset");

        if (sigCanvas.current.isEmpty()) {
            toast.error('Please provide a signature');
            return;
        }

        try {
            const cloudinaryResponse = await fetch("https://api.cloudinary.com/v1_1/dqkd2vnbu/image/upload", {
                method: "POST",
                body: signatureFormData,
            });

            const cloudinaryData = await cloudinaryResponse.json();

            if (cloudinaryData.secure_url) {
                const convertToBoolean = (value) => value === "yes";

                const payload = {
                    employee_id: userId,
                    start_time: convertToTimestamp(formData.clockIn),
                    end_time: convertToTimestamp(formData.clockOut),
                    start_break_time: convertToTimestamp(formData.breakStart),
                    end_break_time: convertToTimestamp(formData.breakEnd),
                    punctuality: convertToBoolean(formData.punctuality),
                    well_mannered: convertToBoolean(formData.attitude),
                    attendance_to_duty: convertToBoolean(formData.performance),
                    authorized_signature: cloudinaryData.secure_url,
                };

                const response = await fetch('https://dewcare.co.uk/mobile/employee/add-timesheet', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                const result = await response.json();

                if (response.ok) {
                    navigate('/success');
                } else {
                    toast.error(`Failed to Submit Timesheet: ${result.message || 'Unknown error'}`);
                }
            } else {
                toast.error('Error uploading to Cloudinary');
            }
        } catch (error) {
            console.error('Error submitting timesheet:', error);
            toast.error('Error submitting timesheet');
        }
    };
    return (
        <Container>
            <section className='timesheet-con'>
                <div className='attendance'>
                    <h4 className='header1'>New Time-Sheet</h4>
                    <div className='text-container'>
                        <div className='time'>
                            <span className='desc'>Job Role</span>
                            <span className='desc'>Date</span>
                        </div>
                        <div className='time2'>
                            <span className='text'>{position || "Loading..."}</span> {/* Dynamically fetched job role */}
                            <span className='text'>{todayDate}</span> {/* Today's date */}
                        </div>
                        <div className='location'>
                            <MdLocationOn style={{ color: '#0093DD', fontSize: 22 }} />
                            <span className='text'>{address || "Loading..."}</span> {/* Dynamically fetched address */}
                        </div>
                        <div className='plan-con'>
                            <div className='plan'>
                                <div className='plan-input'>
                                    <label>Clock-In</label>
                                    <input
                                        step="1800"
                                        type="time"
                                        name="clockIn"
                                        value={formData.clockIn}
                                        onChange={handleInputChange}
                                        className='plan-box'
                                    />
                                </div>
                                <div className='plan-input'>
                                    <label>Clock-Out</label>
                                    <input
                                        step="1800"
                                        type="time"
                                        name="clockOut"
                                        value={formData.clockOut}
                                        onChange={handleInputChange}
                                        className='plan-box'
                                    />
                                </div>
                            </div>
                            <div className='plan'>
                                <div className='plan-input'>
                                    <label>Break-Start</label>
                                    <input
                                        step="1800"
                                        type="time"
                                        name="breakStart"
                                        value={formData.breakStart}
                                        onChange={handleInputChange}
                                        className='plan-box'
                                    />
                                </div>
                                <div className='plan-input'>
                                    <label>Break-End</label>
                                    <input
                                        step="1800"
                                        type="time"
                                        name="breakEnd"
                                        value={formData.breakEnd}
                                        onChange={handleInputChange}
                                        className='plan-box'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h4 className='header2'>Manager’s use only</h4>
                    <div className='manager-review'>
                        <div className='radio'>
                            <span className='checkbox-label'>Punctuality:</span>
                            <div className="radio-button-container">
                                <label>
                                    <input
                                        type="radio"
                                        name="punctuality"
                                        value="yes"
                                        checked={formData.punctuality === "yes"}
                                        onChange={handleInputChange}
                                    />
                                    Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="punctuality"
                                        value="no"
                                        checked={formData.punctuality === "no"}
                                        onChange={handleInputChange}
                                    />
                                    No
                                </label>
                            </div>
                        </div>

                        <div className='radio'>
                            <span className='checkbox-label'>Attitude:</span>
                            <div className="radio-button-container">
                                <label>
                                    <input
                                        type="radio"
                                        name="attitude"
                                        value="yes"
                                        checked={formData.attitude === "yes"}
                                        onChange={handleInputChange}
                                    />
                                    Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="attitude"
                                        value="no"
                                        checked={formData.attitude === "no"}
                                        onChange={handleInputChange}
                                    />
                                    No
                                </label>
                            </div>
                        </div>

                        <div className='radio'>
                            <span className='checkbox-label'>Performance:</span>
                            <div className="radio-button-container">
                                <label>
                                    <input
                                        type="radio"
                                        name="performance"
                                        value="yes"
                                        checked={formData.performance === "yes"}
                                        onChange={handleInputChange}
                                    />
                                    Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="performance"
                                        value="no"
                                        checked={formData.performance === "no"}
                                        onChange={handleInputChange}
                                    />
                                    No
                                </label>
                            </div>
                        </div>
                        <div className='signature-container'>
                    <h4 className='checkbox-label'>Sign:</h4>
                    <SignatureCanvas
                        ref={sigCanvas}
                        penColor='black'
                        canvasProps={{
                            width: 400,
                            height: 150,
                            className: 'sigCanvas'
                        }}
                    />
                </div>
                    </div>
                </div>
                
                <button onClick={handleSubmit} className='btn submit-btn'>Submit</button>
            </section>
        </Container>
    );
};

export default TimeSheet;
