import React, { useState, useEffect } from 'react';
import './index.scss';
import { Container } from '@mui/material';
import usePageBackgroundColor from '../../components/usePageBackgroundColor';
import { getCookie } from '../../utils/cookieHelper'; 
import { toast } from 'react-toastify';

const Profile = () => {
  usePageBackgroundColor('#E1E2E4');

  const [userData, setUserData] = useState({
    full_name: 'Loading...',
    address: 'Loading...',
    email: 'Loading...',
    phone: 'Loading...',
    position: 'Loading...'
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const cookieValue = getCookie('dewcare_user');
      if (!cookieValue) {
        setError('User not logged in or cookie missing.');
        setLoading(false);
        return;
      }

      const userId = cookieValue.userId;

      try {
        const response = await fetch(`https://dewcare.co.uk/mobile/employee/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.statusText}`);
        }

        const data = await response.json();

        setUserData({
          full_name: data.data.full_name,
          address: data.data.address,
          email: data.data.email,
          phone: data.data.phone,
          position: data.data.position
        });
      } catch (error) {
        setError(`Failed to fetch data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleChangePassword = async () => {
    const cookieValue = getCookie('dewcare_user');
    if (!cookieValue) {
      toast.error('User not logged in.');
      return;
    }

    const employeeId = cookieValue.userId;

    try {
      const response = await fetch(`https://dewcare.co.uk/mobile/employee/change-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          employee_id: employeeId,
          current_password: currentPassword,
          new_password: newPassword,
          repeat_new_password: repeatNewPassword,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        const messages = data.errors || {};
        for (const message in messages) {
          toast.error(messages[message]);
        }
      } else {
        toast.success('Password changed successfully!');
        setCurrentPassword('');
        setNewPassword('');
        setRepeatNewPassword('');
      }
    } catch (error) {
      toast.error('An error occurred while changing the password.');
    }
  };

  return (
    <Container>
      <div className='profile'>
        <h1 className="header">Personal Information</h1>
        <div className="detailContainer">
          <div className="detail">
            <p className="desc">Name:</p>
            <p className="object">{loading ? 'Loading...' : userData.full_name}</p>
          </div>
          <div className="detail">
            <p className="desc">Address:</p>
            <p className="object">{loading ? 'Loading...' : userData.address}</p>
          </div>
          <div className="detail">
            <p className="desc">Email Address:</p>
            <p className="object">{loading ? 'Loading...' : userData.email}</p>
          </div>
          <div className="detail">
            <p className="desc">Phone Number:</p>
            <p className="object">{loading ? 'Loading...' : userData.phone}</p>
          </div>
          <div className="detail">
            <p className="desc">Job Role:</p>
            <p className="object">{loading ? 'Loading...' : userData.position}</p>
          </div>
        </div>
        <h1 className="header" style={{ marginTop: '30px' }}>Change Password</h1>
        <div className="passwordCon">
          <div style={{ marginTop: '20px' }}>
            <input 
              type="password" 
              placeholder="Current password" 
              className="input"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <input 
              type="password" 
              placeholder="New password" 
              className="input"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <input 
              type="password" 
              placeholder="Retype new password" 
              className="input"
              value={repeatNewPassword}
              onChange={(e) => setRepeatNewPassword(e.target.value)}
            />
          </div>
          <button className="btn" onClick={handleChangePassword}>
            Change Password
          </button>
        </div>
      </div>
    </Container>
  );
};

export default Profile;
