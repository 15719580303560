import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DrawerMenu from './components/DrawerMenu';
import BottomNav from './components/BottomNav.js';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import Login from './pages/Login/index.js';
import Dashboard from './pages/Dashboard/index.js';
import TimeSheet from './pages/TimeSheet/index.js';
import TimeSheetHistory from './pages/TimeSheet-History/index.js';
import EmployeeContract from './pages/EmployeeContract/index.js';
import UploadContract from './pages/UploadContract/index.js';
import Profile from './pages/Profile/index.js';
import Contact from './pages/Contact/index.js';
import AvailabilitySchedule from './pages/AvailabilitySchedule/index.js';
import SubmittedSheet from './pages/SubmittedSheet/index.js';
import Success from './pages/Success/index.js';
import { getCookie, deleteCookie } from './utils/cookieHelper';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import MobileOnlyApp from './components/MobileOnlyApp.js';

function App() {
  return (
    <MobileOnlyApp>
      <Router>
        <MainApp />
      </Router>
    </MobileOnlyApp>
  );
}

function MainApp() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [bottomNavValue, setBottomNavValue] = useState(0);
  const location = useLocation(); // This gives you the current location
  const navigate = useNavigate();

  // Check cookie on initial mount
  useEffect(() => {
    const checkCookie = () => {
      const cookieValue = getCookie('dewcare_user');
      if (cookieValue) {
        const expirationDate = new Date(cookieValue.expiration);
        const currentDate = new Date();
        if (currentDate > expirationDate) {
          deleteCookie('dewcare_user');
          navigate('/'); // Redirect to login
        }
      } else {
        navigate('/'); // No cookie, redirect to login
      }
    };
    checkCookie();
  }, []); // Empty dependency array

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const isLoginPage = location.pathname === '/';

  // Determine if we are on Contact or Availability Schedule pages
  const isContactOrSchedulePage = location.pathname === '/contact' || location.pathname === '/availability-schedule';

  return (
    <div>
      {!isLoginPage && (
        <>
          <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
            <Toolbar>
              <IconButton edge="start" aria-label="menu" onClick={handleDrawerOpen} sx={{ color: isContactOrSchedulePage ? 'white' : 'black' }}>
                <MenuIcon />
              </IconButton>
              <Box sx={{ flexGrow: 1 }} />
              <Link to='/contact'>
                <IconButton sx={{ color: isContactOrSchedulePage ? 'white' : 'black' }}>
                  <SupportAgentIcon />
                </IconButton>
              </Link>
            </Toolbar>
          </AppBar>
          <DrawerMenu open={drawerOpen} onClose={handleDrawerClose} />
        </>
      )}

      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/home' element={<Dashboard />} />
        <Route path='/timesheet' element={<TimeSheet />} />
        <Route path='/history' element={<TimeSheetHistory />} />
        <Route path='/contract' element={<EmployeeContract />} />
        <Route path='/upload-contract' element={<UploadContract />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/availability-schedule' element={<AvailabilitySchedule />} />
        <Route path='/submitted-sheet/:attendanceId' element={<SubmittedSheet />} />
        <Route path='/success' element={<Success />} />
      </Routes>

      {!isLoginPage && <BottomNav value={bottomNavValue} setValue={setBottomNavValue} />}
      <ToastContainer />
    </div>
  );
}

export default App;
