import React, { useEffect, useState } from 'react';
import './index.scss';
import Calendar from '../../assets/Images/Calendar.svg';
import NoResult from '../../assets/Images/9169253.png';
import { RxCountdownTimer } from "react-icons/rx";
import { HiLocationMarker } from "react-icons/hi";
import { BsFilePersonFill } from "react-icons/bs";
import { Container } from '@mui/material';
import usePageBackgroundColor from '../../components/usePageBackgroundColor';
import { getCookie } from '../../utils/cookieHelper';

const Dashboard = () => {
  usePageBackgroundColor('#EAF7FD');

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchUserData = async () => {
      const cookieValue = getCookie('dewcare_user');
      if (cookieValue) {
        try {
          const response = await fetch(`https://dewcare.co.uk/mobile/employee/${cookieValue.userId}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setUserData(data);
        } catch (error) {
          console.error('Failed to fetch user data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserData();
  }, []); // Run only once on component mount

  const lastAttendance = userData?.attendance?.last_attendance;

  return (
    <Container>
      <div className='dashboard'>
        <section className='timesheet'>
          <h4 className='header1'>Dashboard</h4>
          <div className='textContainer'>
            <div className='days'>
              <p className='number'>
                {loading ? 'Loading...' : userData?.attendance?.all_time} 
              </p> {/* Show 'Loading...' until data is fetched */}
              <img src={Calendar} alt='' />
            </div>
            <p className='text'>Total days in attendance</p>
            <p className='month'>last month</p>
          </div>
        </section>

        {/* Conditional rendering based on whether last attendance is available */}
        {lastAttendance ? (
          <section className="container">
            <h4 className="header2">Last time-sheet recorded</h4>
            <div className="record">
              <p className="title">
                {loading ? 'Loading...' : lastAttendance?.date_created} 
              </p> {/* Show 'Loading...' until date is loaded */}
              <div className="light">
                <div className="time-info">
                  <div className='icon'>
                    <RxCountdownTimer />
                  </div>
                  <p className="text2">
                    {loading ? 'Loading...' : `${lastAttendance?.start_time} - ${lastAttendance?.end_time}`} 
                  </p> {/* Show 'Loading...' for time until data is loaded */}
                </div>
                <div className="details">
                  <div className="detail-item">
                    <HiLocationMarker />
                    <p className="text2 break">
                      {loading ? 'Loading...' : userData?.data?.address} 
                    </p> {/* Show 'Loading...' for address */}
                  </div>
                  <div className="detail-item">
                    <BsFilePersonFill />
                    <p className="text2">
                      {loading ? 'Loading...' : userData?.data?.position} 
                    </p> {/* Show 'Loading...' for position */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="container">
            <h4 className="header2">Last time-sheet recorded</h4>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <img src={NoResult} alt='no result' width='70%' style={{opacity: '0.7'}}/>
              <p style={{textAlign: 'center'}}>No time-sheet recorded</p>
            </div>
          </section>
        )}
      </div>
    </Container>
  );
};

export default Dashboard;
