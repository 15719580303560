import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Bg from '../../assets/Images/bg.svg';
import Logo from '../../assets/Images/logo.svg'; 
import './index.scss';
import { setCookie } from '../../utils/cookieHelper';  // Import cookie helper
import { toast } from 'react-toastify'; // Import toast
import 'react-toastify/dist/ReactToastify.css'; // Import toast CSS if not already in App.js

const Login = () => {
  const navigate = useNavigate();
  
  // State to hold form input values
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Function to handle the login process
  const handleLogin = async () => {
    // Ensure both email and password are filled
    if (!email || !password) {
      toast.error('Please enter both email and password');
      return;
    }
  
    try {
      // POST request to the API with email and password
      const response = await fetch('https://dewcare.co.uk/mobile/employee/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      // Log the raw response for debugging
      console.log('Response status:', response.status);
      
      const data = await response.json();
      
      // Log the response data
      console.log('Response data:', data);
  
      // Check if login was successful
      if (data.success) {
        const userId = data.employee_id; // Assuming 'employee' key contains user details
  
        // Set cookie with userId and navigate to dashboard
        setCookie('dewcare_user', { userId }, 30); // 30 days expiry
        navigate('/home');
      } else {
        // Show toast error message if login failed
        toast.error(data.message || 'Invalid login credentials');
      }
    } catch (error) {
      // Handle fetch error
      toast.error('Failed to login. Please try again.');
      console.error('Login error:', error);
    }
  };
  

  return (
    <>
      <div className="login-container">
        <img src={Bg} alt="Background" className="background" />
        <div className="content">
          <img src={Logo} alt="Logo" className="logo" />
          <div className="form-container">
            <input 
              type="email" 
              className="text-input" 
              placeholder="Email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input 
              type="password" 
              className="text-input" 
              placeholder="Password" 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <button className="login-button" onClick={handleLogin}>
              Login
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
