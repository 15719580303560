import React, { useEffect, useState } from 'react';
import Pdf from '../../assets/Images/Pdf.png';
import './index.scss';
import { Link } from 'react-router-dom';
import { Container } from '@mui/material';
import usePageBackgroundColor from '../../components/usePageBackgroundColor';

const EmployeeContract = () => {
  usePageBackgroundColor('#EAF7FD');

  const [employeeData, setEmployeeData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch the employee ID from cookies or other storage
  const employeeId = 7; // You can replace this with cookie/helper to get the logged-in employee's ID

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const response = await fetch(`https://dewcare.co.uk/mobile/employee/${employeeId}`);
        const data = await response.json();

        if (response.ok) {
          setEmployeeData(data.data); // Storing employee details
        } else {
          console.error('Failed to fetch employee details', data.message);
        }
      } catch (error) {
        console.error('Error fetching employee details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployeeDetails();
  }, [employeeId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!employeeData) {
    return <div>Failed to load employee data</div>;
  }

  // Determine which contract URL to show
  const contractUrl = employeeData.contract
    ? `https://dewcare.co.uk/storage/app/public/docs/download.php?doc=${employeeData.contract}`
    : `https://dewcare.co.uk/storage/app/public/docs/download.php?doc=${employeeData.default_contract}`;

  // Check if the contract has been signed/uploaded
  const contractSigned = employeeData.contract !== null;

  return (
    <Container>
      <section className="contract">
        <h1 className="header">Employee Contract</h1>
        <div className="imgCon">
          <img src={Pdf} alt="Contract" />
          <div className="divider" />
        </div>
        <p className="note">
          {contractSigned 
            ? 'Contract signed, download to view.' 
            : 'Please be informed that a new contract is ready for signing.'}
        </p>
        <button 
          className="btn" 
          onClick={() => window.open(contractUrl, '_blank')}
        >
          Download to view
        </button>
        <div className="textCon">
          {!contractSigned && (
            <>
              <span className="signedText">Signed?</span>
              <Link to='/upload-contract'>
                <button className="uploadButton">
                  Click here to upload
                </button>
              </Link>
            </>
          )}
        </div>
      </section>
    </Container>
  );
};

export default EmployeeContract;
