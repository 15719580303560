import React, { useRef, useState } from 'react';
import { IoCloudUploadOutline } from "react-icons/io5";
import './index.scss';
import { useLocation, useNavigate } from 'react-router-dom'; // To access passed state and redirect
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UploadContract = () => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate(); // Hook for redirection
  const location = useLocation(); // To get passed state (employeeId and handleContractUpload)
  const { employeeId, handleContractUpload } = location.state || {};
  
  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Simulate click on the hidden file input
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && file.type !== 'application/pdf') {
        toast.error('Please upload a .docx or .pdf file');
        return;
      }
      setSelectedFile(file); // Store the selected file
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    if (!selectedFile) {
      toast.error('Please select a file to upload');
      return;
    }

    const formData = new FormData();
    formData.append('employee_id', employeeId); // Append employee ID
    formData.append('document', selectedFile); // Append the selected file with the name "document"

    setLoading(true);

    try {
      const response = await fetch('https://dewcare.co.uk/mobile/upload-contract/employee', {
        method: 'POST',
        body: formData, // Send the form data with the file and employee ID
      });

      if (response.ok) {
        const data = await response.json();
        toast.success('Contract uploaded successfully');
        handleContractUpload(data.contract); // Call the passed function with the uploaded contract

        // Redirect back to EmployeeContract page after successful upload
        navigate('/employee-contract'); 
      } else {
        const errorData = await response.json();
        toast.error('Failed to upload contract');
        console.error('Upload failed:', errorData);
      }
    } catch (error) {
      toast.error('Error uploading contract');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="contract">
      <h1 className="header">Upload Employee Contract</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className='upload' onClick={handleUploadClick}>
          <div className="uploadBox">
            <IoCloudUploadOutline />
          </div>
          <span className='upload-text'>Click to upload</span>
        </div>

        <button type="submit" className="btn" style={{ marginTop: '50px' }} disabled={loading}>
          {loading ? 'Uploading...' : 'Upload'}
        </button>

        {/* Hidden file input */}
        <input
          type="file"
          accept=".docx, .pdf" // Only accept .docx and .pdf files
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </form>
    </section>
  );
};

export default UploadContract;
