import React, { useState, useEffect } from 'react';
import './index.scss';
import successGif from '../../assets/Images/success.gif';
import usePageBackgroundColor from '../../components/usePageBackgroundColor';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../../utils/cookieHelper';

const Success = () => {
  usePageBackgroundColor('#eceeec');

  const [attendanceId, setAttendanceId] = useState(null); // Store attendance ID here
  const navigate = useNavigate();
  const userId = getCookie('dewcare_user')?.userId;

  useEffect(() => {
    const fetchAttendanceRecord = async () => {
      try {
        // Correct API call based on the userId
        const response = await fetch(`https://dewcare.co.uk/mobile/employee/attendance/${userId}`);
        const data = await response.json();

        if (data.success && data.attendance_record && data.attendance_record.id) {
          setAttendanceId(data.attendance_record.id); // Store the fetched attendance ID
        } else {
          console.error('No attendance record found');
        }
      } catch (error) {
        console.error('Error fetching attendance record:', error);
      }
    };

    fetchAttendanceRecord();
  }, [userId]);

  const handleViewSubmittedSheet = () => {
    if (attendanceId) {
      navigate(`/submitted-sheet/${attendanceId}`); // Pass the attendanceId to the SubmittedSheet page
    } else {
      console.error('Attendance ID not found');
    }
  };

  return (
    <div className="success">
      <img src={successGif} alt="Success" className="img" />
      <p className="warning">Timesheet submitted successfully</p>
      <button className="button" onClick={handleViewSubmittedSheet}>
        View
      </button>
    </div>
  );
};

export default Success;
