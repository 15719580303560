import React, { useEffect, useState } from 'react';

const MobileOnlyApp = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Default screen size check

  // Update the state when window is resized
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize); // Add event listener for window resize

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup on component unmount
    };
  }, []);

  if (!isMobile) {
    // Render an error message or redirect for desktop users
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <h1>Access Denied</h1>
        <p>Kindly contact the administrator</p>
      </div>
    );
  }

  // Render the actual app if the screen size is mobile or tablet
  return <>{children}</>;
};

export default MobileOnlyApp;
