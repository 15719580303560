import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useLocation to get state data
import './index.scss'; // Import CSS for custom styles

const SubmittedSheet = () => {
  const location = useLocation(); // Get location to access passed state
  const navigate = useNavigate();
  const attendanceId = location.state?.id; // Extract attendanceId from the state

  const [sheetDetails, setSheetDetails] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState(null);

  useEffect(() => {
    if (!attendanceId) {
      // If no attendanceId is provided, navigate back or show an error
      navigate('/timesheet-history');
      return;
    }

    const fetchSubmittedSheet = async () => {
      try {
        const response = await fetch(`https://dewcare.co.uk/mobile/employee/attendance/${attendanceId}`);
        const data = await response.json();
        if (data.success) {
          setSheetDetails(data.attendance_record); // Set the submitted timesheet data
          setEmployeeDetails(data.employee); // Set the employee details
        }
      } catch (error) {
        console.error('Error fetching submitted sheet:', error);
      }
    };

    fetchSubmittedSheet();
  }, [attendanceId, navigate]);

  return (
    <div className="submitted-sheet-container">
      {sheetDetails && employeeDetails ? (
        <div className="submitted-sheet">
          <h3>Submitted Time-sheet</h3>
          <div className="sheet-details">
            <div className="row">
              <div>
                <strong>Job Role:</strong> 
                <p>{employeeDetails.position}</p>
              </div>
              <div>
                <strong>Date:</strong> 
                <p>{new Date().toLocaleDateString()}</p>
              </div>
            </div>
            <div className="row">
              <p><strong><i className="fas fa-map-marker-alt"></i> Location:</strong> Mr. Johnson's residence</p>
            </div>
            <div className="row">
              <div>
                <strong>Clock-in:</strong>
                <p>{sheetDetails.start_time}</p>
              </div>
              <div>
                <strong>Clock-out:</strong>
                <p>{sheetDetails.end_time}</p>
              </div>
            </div>
            <div className="row">
              <div>
                <strong>Break Start:</strong>
                <p>{sheetDetails.start_break_time}</p>
              </div>
              <div>
                <strong>Break End:</strong>
                <p>{sheetDetails.end_break_time}</p>
              </div>
            </div>
            <div className="punctuality-section">
              <div className="attributes">
                <span>Punctuality:</span>
                <label>{sheetDetails.punctuality ? 'Yes' : 'No'}</label>
              </div>
              <div className="attributes">
                <span>Well-mannered:</span>
                <label>{sheetDetails.well_mannered ? 'Yes' : 'No'}</label>
              </div>
              <div className="attributes">
                <span>Attendance to duty:</span>
                <label>{sheetDetails.attendance_to_duty ? 'Yes' : 'No'}</label>
              </div>
              <div className="attributes">
                <span>Sign:</span>
                <div className="sigcontainer">
                  <img src={sheetDetails.authorized_signature} alt="Authorized Signature" className="signature" />
                </div>
              </div>
            </div>
          </div>
          <p className="submission-info">
            This timesheet has been submitted and cannot be edited anymore. Contact support if you need help.
          </p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default SubmittedSheet;
