import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Avatar, Typography, Box } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HistoryIcon from '@mui/icons-material/History';
import DescriptionIcon from '@mui/icons-material/Description';
import LogoutIcon from '@mui/icons-material/Logout';
import { deleteCookie } from '../utils/cookieHelper';

const drawerWidth = 300;

function DrawerMenu({ open, onClose }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState(null);

  useEffect(() => {
    // Fetch employee data from API
    const fetchEmployeeData = async () => {
      try {
        const response = await fetch('https://dewcare.co.uk/mobile/employee/7'); // Replace 7 with dynamic employee ID if necessary
        const result = await response.json();
        if (result.success) {
          setEmployeeData(result.data);
        }
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    fetchEmployeeData();
  }, []);

  const menuItems = [
    { text: 'Home', icon: <HomeIcon />, path: '/home' },
    { text: 'Profile', icon: <PersonIcon />, path: '/profile' },
    { text: 'Availability Schedule', icon: <ScheduleIcon />, path: '/availability-schedule' },
    { text: 'New Time-sheet', icon: <AssignmentIcon />, path: '/timesheet' },
    { text: 'Time-sheet History', icon: <HistoryIcon />, path: '/history' },
    { text: 'Employee Contract', icon: <DescriptionIcon />, path: '/contract' },
  ];

  // Handle logout
  const handleLogout = () => {
    deleteCookie('dewcare_user'); // Remove the user cookie
    navigate('/'); // Redirect to the login page
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 2,
        }}
      >
        {employeeData ? (
          <>
            {/* Use the passport value to form the profile picture URL */}
            <Avatar 
              sx={{ width: 56, height: 56 }} 
              src={`http://dewcare.co.uk/storage/app/public/docs/${employeeData.passport}`} 
            />
            <Typography variant="h6" sx={{ mt: 1 }}>
              {employeeData.full_name}
            </Typography>
          </>
        ) : (
          <Typography variant="body1">Loading...</Typography>
        )}
      </Box>
      <Divider />
      <List>
        {menuItems.map((item, index) => (
          <ListItem
            button
            key={index}
            component={Link}
            to={item.path}
            onClick={onClose} 
            sx={{
              backgroundColor: location.pathname === item.path ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
              '&:hover': {
                backgroundColor: location.pathname === item.path ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Drawer>
  );
}

export default DrawerMenu;
  